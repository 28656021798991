import { Component, OnInit, Input } from '@angular/core'
import { SharedService } from '../../../services/shared.service'
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { HttpService } from '../../../services/http/http.service'
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { ChatService } from '../../../services/chat/chat.service'
import { select, Store } from '@ngrx/store'
import * as SettingsActions from 'src/app/store/settings/actions'
import * as Reducers from 'src/app/store/reducers'

import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'

@Component({
  selector: 'vb-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  // @Input () parentData

  settings: any = {}
  theme: any

  listOfDisplayData

  validateForm: FormGroup
  isSignVisible = false
  header = ''
  breadcrumbs
  crumbsDot = false
  organizations
  selectedOrg: any
  userData
  showDashboard = false
  crumbsLength
  subscriberName
  existingSign = ''
  alert = false
  isVisible = false
  isVisibleCopyLink = false
  alertMsg = ''
  emailData: FormGroup
  isConfirmLoading = false

  text1: string
  isCopied1: boolean

  emailButtons = false
  module = ''

  viewUrl
  docsVaultOwnSearchResults: any[] = []
  docsVaultaccessibleSearchResults: any[] = []
  docsVaultFoldersSearchResults: any[] = []
  checklistSearchResults: any[] = []
  taskSearchResults: any[] = []
  driveSearchResults: any[] = []
  driveDocumentsSearchResults: any[] = []
  productsSearchResults: any[] = []
  changeCentersSearchResults: any[] = []
  inboxSearchResults: any[] = []
  sentboxSearchResults: any[] = []

  // docsVaultOwnSearchResultsCopy: any[] = []
  // docsVaultaccessibleSearchResultsCopy: any[] = []
  // docsVaultFoldersSearchResultsCopy: any[] = []
  // checklistSearchResultsCopy: any[] = []
  // taskSearchResultsCopy: any[] = []
  // driveSearchResultsCopy: any[] = []
  // driveDocumentsSearchResultsCopy: any[] = []
  // productsSearchResultsCopy: any[] = []
  // changeCentersSearchResultsCopy: any[] = []
  // inboxSearchResultsCopy: any[] = []
  // sentboxSearchResultsCopy: any[] = []

  myControl = new FormControl('')

  filterdata(searchTerm: string): any[] {
    const searchValue = searchTerm.toLowerCase()
    const searchObject = (obj: any) => {
      for (const key in obj) {
        const value = obj[key]
        if (typeof value === 'object') {
          const result = searchObject(value)
          if (result) {
            return true
          }
        } else if (typeof value === 'string' && value.toLowerCase().includes(searchValue)) {
          return true
        }
      }
      return false
    }
    return this.listOfDisplayData.filter(item => searchObject(item))
  }

  constructor(
    public sharedService: SharedService,
    private router: Router,
    private services: HttpService,
    private fb: FormBuilder,
    private httpService: HttpService,
    private notification: NzNotificationService,
    public SharedService: SharedService,
    public chatService: ChatService,
    private store: Store<any>,
  ) {
    this.userData = JSON.parse(this.services.getItem('userData'))
    this.userData.role.forEach(role => {
      if (
        role.userRoleName == 'User' ||
        role.userRoleName == 'Admin' ||
        role.userRoleName == 'Department Admin'
      ) {
        this.showDashboard = true
      }
    })

    this.validateForm = this.fb.group({
      signature: ['', [Validators.required]],
    })

    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.settings = state
      this.theme = state.theme === 'dark'
    })

    this.sharedService.changedOrganization.subscribe(org => {
      if (org) {
        this.selectedOrg = org
        // this.get_data(org);
      }
    })
  }

  ngOnInit() {
    const request = {
      method: 'GET',
      action_url: 'user/get_signature',
    }
    this.services.doHttp(request).subscribe(
      (res: any) => {
        if (res.status) {
          this.existingSign = res.data.signature
        }
      },
      (error: any) => {},
    )

    if (this.showDashboard) {
      this.getOrganizations()
    }

    this.sharedService.subscriber.subscribe(name => {
      this.subscriberName = name
    })
    this.sharedService.changedLoc.subscribe(message => {
      this.header = message
      if (
        message == 'Sent' ||
        message == 'Inbox' ||
        message == 'Compose' ||
        message == 'Messages' ||
        message == 'Drafts' ||
        message == 'Message Details' ||
        message == 'All mails'
      ) {
        this.emailButtons = true
      } else {
        this.emailButtons = false
      }
    })
    this.sharedService.changedBread.subscribe(bread => {
      // alert(bread)
      this.breadcrumbs = bread
      this.crumbsLength = this.breadcrumbs.length
      if (this.breadcrumbs.length > 3) {
        this.crumbsDot = true
      }
    })

    this.sharedService.moduleLoc.subscribe(module => {
      this.module = module
    })

    this.emailData = this.fb.group({
      user: ['', Validators.required],
      password: ['', Validators.required],
      host: ['', Validators.required],
      appPassword: [''],
    })

    this.getEmailCredentials()
  }

  onCopyFailure() {
    alert('copy fail!')
  }

  goToChecklistDashboard() {
    let route = '/user/checklist/checklist-dashboard'
    this.router.navigate([route])
  }

  goToProductModule() {
    let route = '/user/product-module/product-module-dashboard'
    this.router.navigate([route])
  }
  goToBDCollateralModule() {
    let route = '/user/collateral/collateral-dashboard'
    this.router.navigate([route])
  }

  // top bar organization select
  orgChange(data) {
    this.selectedOrg = data
    this.sharedService.changeOrganization(data)
  }

  getOrganizations() {
    // this.isSpinning = true
    const request = {
      method: 'GET',
      action_url: 'request/get_accessable_orgs',
    }
    this.services.doHttp(request).subscribe(
      (res: any) => {
        if (res.status) {
          this.organizations = res.data
          // this.isSpinning = false
        }
      },
      (error: any) => {
        // this.isSpinning = false
        // this.notification.create('error', 'Failed', error.error.msg, {
        //   nzPlacement: 'bottomRight',
        // })
      },
    )
  }

  goBackToReportDashboard() {
    let route = ['/user/reports/reports-dashboard']
    this.router.navigate(route)
  }

  goBackToInbox() {
    let selectedDropDownValues = this.breadcrumbs[0] //of report module
    let route = ['/user/request/mail-inbox']
    this.router.navigate(route, {
      queryParams: { selectedDropDownValues: JSON.stringify(selectedDropDownValues) },
    })
  }

  goToSicDash() {
    let route = ['/user/sic/sic-dashboard']
    this.router.navigate(route)
  }

  showSignModal(): void {
    this.isSignVisible = true
  }

  handleSignOk(): void {
    this.isSignVisible = false
    const request = {
      params: this.validateForm.value,
      method: 'POST',
      action_url: 'user/update_signature',
    }

    this.httpService.doHttp(request)?.subscribe(
      (res: any) => {
        if (res.status) {
          // this.existingSign = this.validateForm.value;
          this.validateForm.value['signature'].replace(/\n/g, '<br>')
          let sign = this.validateForm.value.signature.toString().replace(/\n/g, '<br>')
          this.SharedService.signatureNameFromTopbar({ signature: sign })
          this.notification.create('success', 'Success!', 'Email signature updated successfully!', {
            nzPlacement: 'bottomRight',
          })
        } else {
        }
      },
      err => {
        this.notification.create('error', 'Failed!', err.error.msg, {
          nzPlacement: 'bottomRight',
        })
        this.validateForm.reset()
      },
    )
  }

  handleSignCancel(): void {
    this.isSignVisible = false
  }
  goToLink(url: string) {
    window.open(url, '_blank')
  }

  goToCalendar() {
    let route = '/user/calendar'
    this.router.navigate([route])
  }

  goToChat() {
    let route = '/user/chat'
    this.router.navigate([route])
  }

  linkAccount() {
    this.isVisible = true
  }

  copyMeetLink() {
    this.isVisibleCopyLink = true
    this.text1 = 'https://vmeet.empact.ai/' + this.generateRandomString(10)
  }

  generateRandomString(length) {
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length

    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }

    return result
  }

  handleCancel() {
    this.isVisible = false
  }

  handleCancelCopyLink() {
    this.isVisibleCopyLink = false
  }

  link() {
    this.isConfirmLoading = true
    const request = {
      params: this.emailData.value,
      method: 'POST',
      action_url: 'user/attach_user_email',
    }
    this.services.doHttp1(request).subscribe(
      (res: any) => {
        if (res) {
          this.isConfirmLoading = false
          this.alert = res.status
          this.alertMsg = res.msg
          this.emailData.patchValue({ view: true })
          if (res.status) {
            this.isVisible = false
            this.notification.create('success', 'Success!', res.msg, { nzPlacement: 'bottomRight' })
          } else {
            this.isVisible = true
          }
        }
      },
      (error: any) => {
        this.notification.create('error', 'Failed', error.error.msg, {
          nzPlacement: 'bottomRight',
        })
      },
    )
  }

  getEmailCredentials() {
    const request = {
      method: 'GET',
      action_url: `user/get_email_credentials/${this.userData.email}`,
    }
    this.services.doHttp(request).subscribe(
      (res: any) => {
        if (res.status) {
          this.emailData.patchValue({ user: this.userData.email })
          this.emailData.patchValue({ password: res.data?.password })
          this.emailData.patchValue({ host: res.data?.host })
          this.emailData.patchValue({ appPassword: res.data?.appPassword })
        }
      },
      (error: any) => {},
    )
  }

  togglePreselectedThemes() {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isPreselectedOpen: !this.settings.isPreselectedOpen,
      }),
    )
  }

  setTheme(nextTheme) {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        theme: nextTheme,
      }),
    )
  }

  shareWithMessages() {
    if (this.header != 'Compose') {
      this.router.navigate(['user/request/compose'], {
        queryParams: { meetURL: this.text1 },
        queryParamsHandling: 'merge',
      })
      this.isVisibleCopyLink = false
    }
  }

  shareWithChat() {
    this.router.navigate(['user/chat'], {
      queryParams: { meetURL: this.text1 },
    })
    this.isVisibleCopyLink = false
  }

  openFile(path) {
    const request = {
      params: { path: path },
      method: 'POST',
      action_url: 'mydrive/get_signed_url',
    }
    this.services.doHttp(request).subscribe(
      (res: any) => {
        if (res.status) {
          this.viewUrl = res.signed_url
          if (this.viewUrl) {
            window.open(this.viewUrl, '_blank')
          }
        }
      },
      error => {},
    )
  }

  onInputChange(event) {
    if (event.length < 3) {
      this.docsVaultFoldersSearchResults = []
      this.docsVaultOwnSearchResults = []
      this.docsVaultaccessibleSearchResults = []
      this.checklistSearchResults = []
      this.taskSearchResults = []
      this.driveSearchResults = []
      this.driveDocumentsSearchResults = []
      this.productsSearchResults = []
      this.changeCentersSearchResults = []
      this.inboxSearchResults = []
      this.sentboxSearchResults = []
    } else if (event.length == 3) {
      const request1 = {
        method: 'GET',
        action_url: `user/searchDocsVaultFiles?query=` + event,
      }

      const request2 = {
        method: 'GET',
        action_url: `user/searchDocsVaultFilesAccessible?query=` + event,
      }

      const request3 = {
        method: 'GET',
        action_url: `user/searchDocsVaultFolders?query=` + event,
      }

      const request4 = {
        method: 'GET',
        action_url: `user/searchChecklist?query=` + event,
      }

      const request5 = {
        method: 'GET',
        action_url: `user/searchTask?query=` + event,
      }

      const request6 = {
        method: 'GET',
        action_url: `user/searchDrive?query=` + event,
      }

      const request7 = {
        method: 'GET',
        action_url: `user/searchDriveDocuments?query=` + event,
      }

      const request8 = {
        method: 'GET',
        action_url: `user/searchProducts?query=` + event,
      }

      const request9 = {
        method: 'GET',
        action_url: `user/searchChangeCenters?query=` + event,
      }

      const request10 = {
        method: 'GET',
        action_url: `user/searchInbox?query=` + event,
      }

      const request11 = {
        method: 'GET',
        action_url: `user/searchSentbox?query=` + event,
      }

      const api1$ = this.services.doHttp1(request1)
      const api2$ = this.services.doHttp1(request2)
      const api3$ = this.services.doHttp1(request3)
      const api4$ = this.services.doHttp1(request4)
      const api5$ = this.services.doHttp1(request5)
      const api6$ = this.services.doHttp1(request6)
      const api7$ = this.services.doHttp1(request7)
      const api8$ = this.services.doHttp1(request8)
      const api9$ = this.services.doHttp1(request9)
      const api10$ = this.services.doHttp1(request10)
      const api11$ = this.services.doHttp1(request11)

      api1$.subscribe((res1: any) => {
        this.docsVaultOwnSearchResults = res1
        // this.docsVaultOwnSearchResultsCopy = res1
      })

      api2$.subscribe((res2: any) => {
        this.docsVaultaccessibleSearchResults = res2
        // this.docsVaultaccessibleSearchResultsCopy = res2
      })

      api3$.subscribe((res3: any) => {
        this.docsVaultFoldersSearchResults = res3
        // this.docsVaultFoldersSearchResultsCopy = res3
      })

      api4$.subscribe((res4: any) => {
        this.checklistSearchResults = res4
        // this.checklistSearchResultsCopy = res4
      })

      api5$.subscribe((res5: any) => {
        this.taskSearchResults = res5
        // this.taskSearchResultsCopy = res5
      })

      api6$.subscribe((res6: any) => {
        this.driveSearchResults = res6
        // this.driveSearchResultsCopy = res6
      })

      api7$.subscribe((res7: any) => {
        this.driveDocumentsSearchResults = res7
        // this.driveDocumentsSearchResultsCopy = res7
      })

      api8$.subscribe((res8: any) => {
        this.productsSearchResults = res8
        // this.productsSearchResultsCopy = res8
      })

      api9$.subscribe((res9: any) => {
        this.changeCentersSearchResults = res9
        // this.changeCentersSearchResultsCopy = res9
      })

      api10$.subscribe((res10: any) => {
        this.inboxSearchResults = res10
        // this.inboxSearchResultsCopy = res10
      })

      api11$.subscribe((res11: any) => {
        this.sentboxSearchResults = res11
        // this.sentboxSearchResultsCopy = res11
      })
    } else {
      const requestExtend = {
        method: 'GET',
        action_url: `user/searchExtend?query=` + event,
      }
      const apiExtend$ = this.services.doHttp1(requestExtend)
      apiExtend$.subscribe((resExtend: any) => {
        this.docsVaultFoldersSearchResults = resExtend.DocsVaultFolders
        this.docsVaultOwnSearchResults = resExtend.DocsVaultFiles
        this.docsVaultaccessibleSearchResults = resExtend.DocsVaultFilesAccessible
        this.checklistSearchResults = resExtend.Checklist
        this.taskSearchResults = resExtend.Task
        this.driveSearchResults = resExtend.Drive
        this.driveDocumentsSearchResults = resExtend.DriveDocuments
        this.productsSearchResults = resExtend.Products
        this.changeCentersSearchResults = resExtend.ChangeCenters
        this.inboxSearchResults = resExtend.Inbox
        this.sentboxSearchResults = resExtend.Sentbox
      })

      // this.docsVaultOwnSearchResults = this.getFilteredData(
      //   this.docsVaultOwnSearchResultsCopy,
      //   event,
      // )
      // this.docsVaultFoldersSearchResults = this.getFilteredData(
      //   this.docsVaultFoldersSearchResultsCopy,
      //   event,
      // )
      // this.docsVaultaccessibleSearchResults = this.getFilteredData(
      //   this.docsVaultaccessibleSearchResultsCopy,
      //   event,
      // )
      // this.checklistSearchResults = this.getFilteredData(this.checklistSearchResultsCopy, event)
      // this.taskSearchResults = this.getFilteredData(this.taskSearchResultsCopy, event)
      // this.driveSearchResults = this.getFilteredData(this.driveSearchResultsCopy, event)
      // this.driveDocumentsSearchResults = this.getFilteredData(
      //   this.driveDocumentsSearchResultsCopy,
      //   event,
      // )
      // this.productsSearchResults = this.getFilteredData(this.productsSearchResultsCopy, event)
      // this.changeCentersSearchResults = this.getFilteredData(
      //   this.changeCentersSearchResultsCopy,
      //   event,
      // )
      // this.inboxSearchResults = this.getFilteredData(this.inboxSearchResultsCopy, event)
      // this.sentboxSearchResults = this.getFilteredData(this.sentboxSearchResultsCopy, event)
    }
  }

  // getFilteredData(data, event) {
  //   return data.filter(item =>
  //     Object.values(item).some(value =>
  //       value && typeof value === 'object'
  //         ? Object.values(value).some(
  //             innerValue =>
  //               innerValue &&
  //               innerValue
  //                 .toString()
  //                 .toLowerCase()
  //                 .includes(event.toLowerCase()),
  //           )
  //         : value &&
  //           value
  //             .toString()
  //             .toLowerCase()
  //             .includes(event.toLowerCase()),
  //     ),
  //   )
  // }

  isVisibleTop = false
  globalSearch() {
    this.isVisibleTop = true
  }

  handleOkTop(): void {
    this.isVisibleTop = false
  }
}
