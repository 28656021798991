// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authenticated: false,
  hmr: false,
}

export const userEnvironment = {
  production: true,
  baseURL: 'https://app.moxiedb.com',
  socketURL: 'https://app.moxiedb.com',
  sso_api_username: '6doesoat1qcmkl3hpgb0u7787l',
  sso_api_pwd: '1g1pfus7s56f4pnmjaok91jmfniqno7bunup3hci9gsorpfnlk56',
  loginURL:
    'https://dev-moxiedb.auth.us-east-1.amazoncognito.com/login?client_id=6doesoat1qcmkl3hpgb0u7787l&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=https://app.moxiedb.com/user-utility',
  redirectURL: 'https://app.moxiedb.com/user-utility',
  cognitoTokenURL: 'https://dev-moxiedb.auth.us-east-1.amazoncognito.com/oauth2/token',
  logout: 'https://app.moxiedb.com/auth/login',
}

// export const userEnvironment = {
//   production: true,
//   baseURL: 'https://app.moxiedb.com',
//   socketURL: 'https://app.moxiedb.com',
//   sso_api_username: 's5auvuad56fibujulilc28p0l',
//   sso_api_pwd: '1lpt1dsc5lvoumccj1og30k4v9c4qqdbfh9btbl5mclcn9tchpp2',
//   loginURL:
//     'https://app-moxiedb.auth.us-east-1.amazoncognito.com/login?client_id=s5auvuad56fibujulilc28p0l&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=https://app.moxiedb.com/user-utility',
//   redirectURL: 'https://app.moxiedb.com/user-utility',
//   cognitoTokenURL: 'https://app-moxiedb.auth.us-east-1.amazoncognito.com/oauth2/token',
//   logout:
//     'https://app.moxiedb.com/auth/login',
// }

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
