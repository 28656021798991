import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Router } from '@angular/router'
import { Observable } from 'rxjs/index'
import { NotifierService } from 'angular-notifier'
import { userEnvironment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public token: any = false
  public userData: any = false
  public playingRole: any = false
  public menuData: any = []

  public validations = {
    email: /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i,
    otp: /^\d{6}$/,
  }
  public baseUrl: any = '/api/apiv1/'

  constructor(
    private http: HttpClient,
    private notification: NotifierService,
    private router: Router,
  ) {}

  public doHttp(request) {
    let options
    if (this.token) {
      options = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
        .set('pragma', 'no-cache')
        .set('Authorization', `Bearer ${this.token}`)
        .set('Role', `${this.playingRole}`)
    } else {
      options = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('pragma', 'no-cache')
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
    }

    if (request.method === 'GET') {
      const params = ''
      return this.http.get(this.baseUrl + request.action_url + params, {
        headers: options,
      })
    } else if (request.method === 'POST') {
      return this.http.post(this.baseUrl + request.action_url, request.params, {
        headers: options,
      })
    } else if (request.method === 'PUT') {
      return this.http.put(this.baseUrl + request.action_url, JSON.stringify(request.params), {
        headers: options,
      })
    } else if (request.method === 'DELETE') {
      return this.http.delete(this.baseUrl + request.action_url, {
        headers: options,
      })
    }
  }

  public doHttp1(request) {
    let options
    if (this.token) {
      options = new HttpHeaders()
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
        .set('pragma', 'no-cache')
        .set('Authorization', `Bearer ${this.token}`)
        .set('Role', `${this.playingRole}`)
    } else {
      options = new HttpHeaders()
        .set('pragma', 'no-cache')
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
    }

    if (request.method === 'GET') {
      const params = ''
      return this.http.get(this.baseUrl + request.action_url + params, {
        headers: options,
      })
    } else if (request.method === 'POST') {
      return this.http.post(this.baseUrl + request.action_url, request.params, {
        headers: options,
      })
    } else if (request.method === 'PUT') {
      return this.http.put(this.baseUrl + request.action_url, request.params, {
        headers: options,
      })
    } else if (request.method === 'DELETE') {
      return this.http.delete(this.baseUrl + request.action_url, {
        headers: options,
      })
    }
  }

  /**
   * store user data
   * @param data
   */
  public storeUserData(data) {
    this.token = data['tokenId']
    this.setItem('token', data['tokenId'])
  }

  /* set key value from local storage */

  public setItem(key, item) {
    window.localStorage.setItem(key, item)
  }

  /* get key value from local storage */

  public getItem(key) {
    const temp = window.localStorage.getItem(key)
    return temp
  }

  public removeItem(key) {
    window.localStorage.removeItem(key)
  }

  public errorMsg(error) {
    if (error.status === 401) {
      if (error.error.auth_declined) {
      } else {
        this.logout()
      }
    }
  }

  success_notification(msg) {
    this.notification.notify('success', msg)
  }

  error_notification(msg) {
    this.notification.notify('error', msg)
  }

  public logout() {
    this.token = false
    this.userData = false
    this.removeItem('tokenId')
    this.removeItem('userData')
    window.location.assign(userEnvironment.logout)
  }

  public sliceData(msg) {
    if (msg) {
      const msges = msg.split('\n\n')
      if (msges.length > 0) {
        if (msges.length == 1) {
          if (msges[0].length > 35) {
            return msges[0].substring(0, 35)
          }
        } else {
          if (msges[0].length > 35) {
            return msges[0].substring(0, 35)
          } else {
            if (msges[1].length > 35) {
              return msges[0] + msges[1].substring(0, 35)
            } else {
              return msges[0] + msges[1]
            }
          }
        }
      } else {
        return msg
      }
    } else {
      return msg
    }
  }
}
