import { Component } from '@angular/core'
import { select, Store } from '@ngrx/store'
import * as UserActions from 'src/app/store/user/actions'
import * as Reducers from 'src/app/store/reducers'
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms'
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { HttpService } from '../../../../services/http/http.service'
import { MustMatch } from '../../../../helpers/must-match.vaidator'

@Component({
  selector: 'vb-topbar-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class TopbarUserMenuComponent {
  name: string = ''
  role: string = ''
  email: string = ''
  phone: string = ''

  isVisible = false
  isVisible1 = false
  isConfirmLoading: any = false
  isConfirmLoading1: any = false

  editProfileForm: FormGroup
  passChange: FormGroup

  fileToUpload: File = null
  profilePic: any = 'assets/images/default.png'
  profilePicTemp: any = 'assets/images/default.png'
  userData: any = {}

  constructor(
    private store: Store<any>,
    private services: HttpService,
    private fb: FormBuilder,
    private router: Router,
    private notification: NzNotificationService,
  ) {
    this.userData = JSON.parse(this.services.getItem('userData'))

    // sivagopi commented
    // this.store.pipe(select(Reducers.getUser)).subscribe(state => {
    //   this.name = state.name
    //   this.role = state.role
    //   this.email = state.email
    // })

    this.editProfileForm = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phone: [null, Validators.required],
      workingLocation: [null, Validators.required],
    })
    this.passChange = this.fb.group(
      {
        prePass: ['', Validators.required],
        proPass: ['', Validators.required],
        reProPass: ['', Validators.required],
        accessToken: ['', Validators.required],
      },
      {
        validator: MustMatch('proPass', 'reProPass'),
      },
    )

    this.editProfileForm.setValue({
      firstName: this.services.userData.firstName,
      lastName: this.services.userData.lastName,
      phone: this.services.userData.phone,
      workingLocation: this.services.userData.workingLocation,
    })

    this.name = this.services.userData.firstName
    this.role = this.services.playingRole
    this.email = this.services.userData.email
    this.phone = this.services.userData.phone
    if (this.services.userData.logoUrl) {
      this.profilePic = this.services.userData.logoUrl
      this.profilePicTemp = this.services.userData.logoUrl
    }
  }

  logout() {
    this.services.logout()
  }

  showModal(): void {
    this.isVisible = true
  }

  showModal1(): void {
    this.isVisible1 = true
  }

  handleCancel(): void {
    this.isVisible = false
  }

  handleCancel1(): void {
    this.isVisible1 = false
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0)

    const reader = new FileReader()
    reader.onload = e => (this.profilePicTemp = reader.result)

    reader.readAsDataURL(this.fileToUpload)
  }

  handleOk() {
    this.isConfirmLoading = true

    const formData = new FormData()
    formData.append('file', this.fileToUpload)
    formData.append('firstName', this.editProfileForm.value.firstName)
    formData.append('lastName', this.editProfileForm.value.lastName)
    formData.append('phone', this.editProfileForm.value.phone)
    formData.append('workingLocation', this.editProfileForm.value.workingLocation)

    const request = {
      params: formData,
      method: 'POST',
      action_url: 'user/update_profile_data',
    }
    this.services.doHttp1(request).subscribe(
      (res: any) => {
        if (res.status) {
          this.isConfirmLoading = false
          this.isVisible = false

          res.data.orgLogo = this.userData.orgLogo
          res.data.orgName = this.userData.orgName
          // res.data.userContent = true
          res.data.userId = this.userData.userId

          this.services.userData = res.data
          this.services.setItem('userData', JSON.stringify(res.data))

          this.name = this.services.userData.firstName
          this.role = this.services.playingRole
          this.email = this.services.userData.email
          this.phone = this.services.userData.phone

          if (this.services.userData.logoUrl) {
            this.profilePic = this.services.userData.logoUrl
            this.profilePicTemp = this.services.userData.logoUrl
          }
          this.notification.create('success', 'Success!', res.msg, { nzPlacement: 'bottomRight' })
          // window.location.reload()
        }
      },
      error => {
        this.isConfirmLoading = false

        this.notification.create('error', 'Failed!', error.error.msg, {
          nzPlacement: 'bottomRight',
        })
      },
    )
  }

  handleOk1() {
    this.isConfirmLoading1 = true

    const request = {
      params: this.passChange.value,
      method: 'POST',
      action_url: 'user/update_profile_password',
    }
    this.services.doHttp(request).subscribe(
      (res: any) => {
        if (res.status) {
          this.isConfirmLoading1 = false
          this.isVisible1 = false
          this.passChange.reset()
          this.notification.create('success', 'Success!', res.msg, { nzPlacement: 'bottomRight' })
        }
      },
      error => {
        this.isConfirmLoading1 = false
        this.notification.create('error', 'Failed!', error.error.msg, {
          nzPlacement: 'bottomRight',
        })
      },
    )
  }
}
